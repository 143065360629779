<template>
  <div class="container">
    <div class="page-container">
      <el-form :inline="true" class="fr-s-s">
        <el-form-item>
          <el-button
            type="primary"
            plain
            v-if="permission.saveIntegralGoodsClassInfo"
            @click="onAdd"
            >添加</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        ref="table"
        :data="tableData"
        border
        stripe
        :row-key="'rateId'"
        v-loading="showLoading"
        :height="tableHeight"
      >
        <el-table-column prop="rateId" label="编号" align="center" width="60">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="goodsClassName" label="商品分类名称" min-width="120">
        </el-table-column>
        <el-table-column prop="isShow" label="是否显示" align="center" width="100px">
          <template slot-scope="scope">
            <i :class="[scope.row.isShow == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
          </template>
        </el-table-column>
        <el-table-column prop="orderWeight" label="权重" align="center" width="100">
        </el-table-column>
        <el-table-column label="操作" width="110px" align="center">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="top"
              v-if="permission.updateIntegralGoodsClassInfo"
            >
              <el-button
                class="success"
                type="text"
                icon="el-icon-edit-outline"
                @click="clickEditInfo(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              v-if="permission.removeIntegralGoodsClassInfo"
            >
              <el-popconfirm
                title="您确定要删除么？"
                icon-color="red"
                @confirm="deleteAdmin(scope.row, scope.$index)"
              >
                <el-button
                  slot="reference"
                  icon="el-icon-delete"
                  type="text"
                  class="danger"
                >
                </el-button>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        ref="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="query.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>
    </div>
    <!-- 添加商品分类-->
    <el-dialog
      :title="`${editForm.goodsClassId ? '编辑' : '添加'}商品分类`"
      :visible.sync="addOrEditDialog"
      :modal="false"
      :destroy-on-close="true"
      fullscreen
      @close="resetAddOrEditData"
    >
      <el-form label-width="150px" ref="editForm" :model="editForm" :rules="addRules">
        <el-form-item label="商品分类名称" prop="goodsClassName">
          <el-input
            size="medium"
            v-model="editForm.goodsClassName"
            placeholder="请输入商品分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否显示" prop="isShow">
          <el-checkbox
            v-model="editForm.isShow"
            :true-label="1"
            :false-label="0"
          ></el-checkbox>
        </el-form-item>
        <el-form-item label="权重" prop="orderWeight">
          <el-input
            type="number"
            v-model="editForm.orderWeight"
            placeholder="请填写权重"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickAddOrEditCancel">取 消</el-button>
        <el-button
          type="primary"
          :loading="addOrEditBtnLoading"
          @click="clickAddOrEditConfirm"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getList } from "@/tools/getList.js";
import goodsManager from "@/request/goodsManager.js";
export default {
  mixins: [getList],
  data() {
    return {
      requst: {
        module: goodsManager,
        url: "integralGoodsClassInfoList",
        addUrl: "saveIntegralGoodsClassInfo",
        updateUrl: "updatenItegralGoodsClassInfo",
      },
      query: {},
      //添加
      addOrEditDialog: false,
      addOrEditBtnLoading: false,
      editForm: {
        goodsClassId: 0,
        goodsClassName: "",
        isShow: 1,
        orderWeight: 9,
      },
      addRules: {
        goodsClassName: [
          { required: true, message: "请输入商品分类名称", trigger: "blur" },
        ],
        orderWeight: [{ required: true, message: "请输入权重", trigger: "blur" }],
      },
    };
  },
  methods: {
    // 点击添加
    onAdd() {
      this.addOrEditDialog = true;
    },

    // 点击添加---取消
    clickAddOrEditCancel() {
      this.resetAddOrEditData();
    },
    // 点击添加---确定
    clickAddOrEditConfirm() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          try {
            this.addOrEditBtnLoading = true;
            let dataValue = JSON.parse(JSON.stringify(this.editForm));
            let data = await this.requst.module[
              this.editForm.goodsClassId > 0 ? this.requst.updateUrl : this.requst.addUrl
            ](dataValue);
            if (data.code == 200) {
              this.$message({
                message: data.message,
                type: "success",
              });
              this.refresCurrentPagehData();
              this.resetAddOrEditData();
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.addOrEditBtnLoading = false;
          }
        }
      });
    },

    // 清除添加数据
    resetAddOrEditData() {
      this.addOrEditDialog = false;
      this.editForm = {
        goodsClassId: 0,
        goodsClassName: "",
        isShow: 1,
        orderWeight: 9,
      };
    },
    // 点击编辑信息
    async clickEditInfo(row) {
      this.editForm = {
        goodsClassId: row.goodsClassId,
        goodsClassName: row.goodsClassName,
        isShow: row.isShow,
        orderWeight: row.orderWeight,
      };
      this.addOrEditDialog = true;
    },

    // 点击删除
    async deleteAdmin(row, index) {
      try {
        let { code, message } = await goodsManager.removeIntegralGoodsClassInfo({
          goodsClassId: row.goodsClassId,
        });
        if (code === 200) {
          this.$message({
            message: message,
            type: "success",
          });
          this.delRefresh(index);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
