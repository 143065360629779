<template>
  <div class="container">
    <div class="page-container">
      <el-form :inline="true" class="fr-s-s warp">
        <el-form-item>
          <el-input type="text" style="width:200px" placeholder="搜索订单编号、用户名、门店" v-model="query.keyword"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="text" placeholder="请输入商品名称" v-model="query.goodsName"></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-input type="text" placeholder="用户名" v-model="query.nickName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="text" placeholder="门店名称" v-model="query.storeName"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-date-picker size="small" value-format="timestamp" style="width: 190px" v-model="query.startTime"
            type="datetime" placeholder="请选择开始时间" :picker-options="pickerOptionsStart">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker size="small" value-format="timestamp" style="width: 190px" v-model="query.endTime"
            type="datetime" placeholder="请选择结束时间" :picker-options="pickerOptionsEnd">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select size="small" v-model="query.orderState" placeholder="请选择订单状态" clearable>
            <el-option v-for="item in searchForm.orderStateList" :key="item.value" :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select size="small" v-model="query.orderSource" placeholder="请选择订单来源" clearable>
            <el-option v-for="item in searchForm.orderSourceList" :key="item.value" :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select size="small" v-model="query.orderType" placeholder="请选择订单类型" clearable>
            <el-option v-for="item in searchForm.orderTypeList" :key="item.value" :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-select size="small" v-model="query.isOld" placeholder="请选择是否老订单" clearable>
            <el-option v-for="item in searchForm.isOldList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" plain @click="refreshData">搜索</el-button>
        </el-form-item>
        <el-form-item v-if="permission.exportExcel">
          <el-button type="primary" plain v-if="permission.exportExcel" @click="exportExcelHandle">导出</el-button>
        </el-form-item>
        <!-- <el-form-item v-if="permission.exportExcel">
          <el-button type="primary" plain v-if="permission.newExportExcel"
            @click="newExportExcelHandle">新订单导出</el-button>
        </el-form-item> -->
        <el-form-item v-if="permission.exportExcel">
          <el-button type="primary" plain v-if="permission.exportExcel"
            @click="downloadDeliveryTemplateHandle">下载发货模版</el-button>
        </el-form-item>
        <el-form-item v-if="permission.excelImport">
          <el-upload style="width: 0px;height:0px" ref="elUploadRef" :action="uploadUrl" accept=".xlsx"
            :limit="imgLimit" :file-list="productImgList" :multiple="isMultiple" :show-file-list="false"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :on-exceed="handleExceed"
            :on-error="imgUploadError">
            <el-button type="primary" plain v-if="permission.excelImport">批量发货</el-button>
          </el-upload>
          <!-- <el-button type="primary" plain @click="excelImportHandle" v-if="permission.excelImport">批量发货</el-button> -->
        </el-form-item>
      </el-form>

      <el-table ref="table" :data="tableData" border stripe :row-key="'orderId'" v-loading="showLoading"
        :height="tableHeight">
        <el-table-column type="expand" width="40">
          <template slot-scope="props">
            <el-descriptions title="物流信息" v-if="props.row.deliveryTime != 0" border :column="3">
              <el-descriptions-item label="物流公司">{{
                props.row.shippingCompany
                }}</el-descriptions-item>
              <el-descriptions-item label="物流单号">{{
                props.row.courierNumber
                }}</el-descriptions-item>
              <el-descriptions-item label="发货时间">{{
                props.row.deliveryTime | formatDate
              }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="时间" border :column="3">
              <el-descriptions-item label="确认收货时间">{{
                props.row.confirmReceiptTime | formatDate
                }}</el-descriptions-item>
              <el-descriptions-item label="自提时间">{{
                props.row.pickUpTime | formatDate
                }}</el-descriptions-item>
              <el-descriptions-item label="支付时间">{{
                props.row.paymentTime | formatDate
                }}</el-descriptions-item>
            </el-descriptions>
          </template>
        </el-table-column>
        <el-table-column prop="orderId" label="编号" align="center" width="50">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="订单编号" prop="orderSn" width="140"> </el-table-column>
        <el-table-column label="用户名" prop="nickName" min-width="90"> </el-table-column>
        <el-table-column label="商品名称" prop="goodsName" min-width="140">
        </el-table-column>
        <el-table-column label="门店名称" prop="storeName" min-width="110">
        </el-table-column>
        <el-table-column label="购买数量" prop="buyNumber" width="70" align="center">
        </el-table-column>
        <el-table-column label="订单状态" width="70" align="center">
          <template slot-scope="scope">
            {{ scope.row.orderState | getStateName(searchForm.orderStateList) }}
          </template>
        </el-table-column>
        <el-table-column label="订单类型" align="center" width="70">
          <template slot-scope="scope">
            <span v-if="scope.row.orderType == 1">物流</span>
            <span v-else-if="scope.row.orderType == 2">自提</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="订单来源" align="center" width="70">
          <template slot-scope="scope">
            {{ scope.row.orderSource | getStateName(searchForm.orderSourceList) }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="是否老订单" align="center" width="70">
          <template slot-scope="scope">
            <span v-if="scope.row.isOld == 1">是</span>
            <span v-else-if="scope.row.isOld == 0">否</span>
            <span v-else>-</span>
          </template>
        </el-table-column> -->
        <el-table-column label="运费" align="center" width="70" prop="logisticsFees">
        </el-table-column>
        <el-table-column label="支付积分" align="center" width="70" prop="orderPayIntegral">
        </el-table-column>
        <el-table-column label="商品价格" align="center" width="70" prop="goodsPrice">
        </el-table-column>
        <el-table-column label="订单商品总额" align="center" width="100" prop="orderPayAmount">
        </el-table-column>
        <el-table-column label="订单详情" align="center" width="70">
          <template slot-scope="scope">
            <a @click="showContentHandle(scope.row, 2)" v-if="scope.row.orderType == 1">
              <el-link type="primary">查看</el-link>
            </a>
          </template>
        </el-table-column>
        <!-- <el-table-column label="订单备注" align="center" width="70">
          <template slot-scope="scope">
            <a @click="showContentHandle(scope.row, 1)">
              <el-link type="primary">查看</el-link>
            </a>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="查看物流" align="center" width="70">
          <template slot-scope="scope">
            <a @click="showContentHandle(scope.row, 3)" v-if="scope.row.orderType == 1 && (scope.row.orderState == 4 || scope.row.orderState == 5)">
              <el-link type="primary">查看物流</el-link>
            </a>
          </template>
        </el-table-column> -->
        <el-table-column label="下单时间" align="center" width="135">
          <template slot-scope="scope">
            {{ scope.row.orderTime | formatDate }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80px" align="center" fixed="right">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="发货" placement="top"
              v-if="permission.deliverGoods && scope.row.orderState == 2">
              <el-button class="success" type="text" icon="el-icon-s-promotion"
                @click="deliverGoodsHandle(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="查看物流" placement="top"
              v-if="scope.row.orderType == 1 && (scope.row.orderState == 4 || scope.row.orderState == 5)">
              <el-button class="success" type="text" icon="el-icon-truck"
                @click="showContentHandle(scope.row, 3)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="确认自提" placement="top"
              v-if="permission.confirmReceipt && scope.row.orderState == 3">
              <el-popconfirm title="确认用户已自提？" icon-color="red" @confirm="confirmReceiptHandle(scope.row)">
                <el-button slot="reference" icon="el-icon-shopping-bag-1" type="text" class="danger">
                </el-button>
              </el-popconfirm>
            </el-tooltip>

          </template>
        </el-table-column>
      </el-table>
      <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="query.page" :page-sizes="[10, 30, 50, 100]" :page-size="query.page_size"
        layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>

    <!-- 订单发货 -->
    <el-dialog title="订单发货" :visible.sync="dialogShowSendOrderVisible" :modal="false" :destroy-on-close="true"
      fullscreen @close="closeSendOrder">
      <el-form :model="logisticsModel" :rules="logisticsOrderRules" ref="logisticsRef" label-width="120px"
        class="demo-ruleForm">
        <el-form-item label="物流公司" prop="shippingCompany">
          <el-input size="medium" v-model="logisticsModel.shippingCompany" placeholder="请输入物流公司"></el-input>
        </el-form-item>
        <el-form-item label="物流单号" prop="courierNumber">
          <el-input size="medium" v-model="logisticsModel.courierNumber" placeholder="请输入物流单号"></el-input>
        </el-form-item>
      </el-form>
      <!-- 取消确定 -->
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogShowSendOrderVisible = false">取 消</el-button>
        <el-button size="medium" icon="el-icon-document-checked" type="primary" :loading="sendOrderLoading"
          @click="sendOrderSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>

    <el-dialog title="收货地址" :visible.sync="dialogLookAddressVisible" :modal="false" :destroy-on-close="true" fullscreen>
      <OrderAddressInfo :orderId="lookAddressModel.orderId" :orderNotes="lookAddressModel.orderNotes"
        :courierNumber="lookAddressModel.courierNumber" :shippingCompany="lookAddressModel.shippingCompany"
        :isHaveLogistics="lookAddressModel.isHaveLogistics" :deliveryTime="lookAddressModel.deliveryTime"
        @changeLogistics='changeLogisticsHandle' v-if="dialogLookAddressVisible" />
    </el-dialog>
    <el-dialog title="查看物流" :visible.sync="dialogLooklogisticsVisible" :modal="false" :destroy-on-close="true"
      fullscreen>
      <LookLogistics :courierNumber="lookAddressModel.courierNumber" :shippingCompany="lookAddressModel.shippingCompany"
        v-if="dialogLooklogisticsVisible" />
    </el-dialog>
  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import goodsManager from '@/request/goodsManager.js'
import { baseconfig } from '@/config.js'
import ShowContent from '@/components/common/subgroup/ShowContent.vue'
import OrderAddressInfo from './OrderAddressInfo.vue'
import LookLogistics from './LookLogistics.vue'

export default {
  mixins: [getList],
  props: ['goodsId'],
  components: {
    ShowContent,
    OrderAddressInfo,
    LookLogistics,
  },
  data() {
    return {
      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.query.endTime != '' && this.query.endTime != null) {
            return (
              time.getTime() > Date.now() || time.getTime() > this.query.endTime
            )
          } else {
            return time.getTime() > Date.now()
          }
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          return (
            time.getTime() < this.query.startTime || time.getTime() > Date.now()
          )
        },
      },
      requst: {
        module: goodsManager,
        url: 'integralOrderInfoList',
        addUrl: '',
        updateUrl: '',
      },
      query: {
        // orderSn: '',
        keyword: '',
        orderState: '',
        orderSource: '',
        // nickName: '',
        goodsName: '',
        orderType: '',
        startTime: '',
        endTime: '',
        isOld: 1,
      },
      dialogSeeContent: false,
      rowInfo: {
        title: '',
        content: '',
      },
      searchForm: {
        orderStateList: [
          {
            value: 1,
            label: '待支付',
          },
          {
            value: 2,
            label: '待发货',
          },
          {
            value: 3,
            label: '待自提',
          },
          {
            value: 4,
            label: '待收货',
          },
          {
            value: 5,
            label: '已完成',
          },
          {
            value: 6,
            label: '已取消',
          },
        ],
        
        // 订单来源【1:积分兑换；2:领奖订单；3院线项目订单；4家居项目订单】
        orderSourceList: [
          {value: 1,label: '积分兑换',},
          {value: 2,label: '领奖订单',},
          // 2024-05-20 二期新增
          {value: 3,label: '院线订单',},
          {value: 4,label: '家居订单',},
        ],
        orderTypeList: [
          {
            value: 1,
            label: '物流',
          },
          {
            value: 2,
            label: '自提',
          },
        ],
        // 2024-05-20 是否老订单 0否 1是
        isOldList: [
          { value: 0, label: '否', },
          { value: 1, label: '是', },
        ],
      },

      //发货
      sendOrderLoading: false,
      dialogShowSendOrderVisible: false,
      logisticsModel: {
        shippingCompany: '',
        courierNumber: '',
        orderId: 0,
      },
      logisticsOrderRules: {
        shippingCompany: [
          { required: true, message: '请输入物流公司名称', trigger: 'blur' },
        ],
        courierNumber: [
          { required: true, message: '请输入物流单号', trigger: 'blur' },
        ],
      },
      //批量发货
      uploadUrl: baseconfig.baseUrl + '/web/integralOrderInfo/excelImport',
      isMultiple: false,
      //查看地址
      dialogLookAddressVisible: false,
      dialogLooklogisticsVisible: false,
      lookAddressModel: {
        orderId: 0,
        orderNotes: '',
        courierNumber: '',
        shippingCompany: '',
        isHaveLogistics: false
      },
      imgLimit: 1,
      productImgList: []
    }
  },

  methods: {
    showContentHandle(row, type) {
      switch (type) {
        case 1:
          this.rowInfo.title = '备注'
          this.rowInfo.content = row.orderNotes
          this.dialogSeeContent = true
          break
        case 2:
          if (this.permission.lookOrderAddressInfo) {
            this.lookAddressModel.orderId = row.orderId
            this.lookAddressModel.orderNotes = row.orderNotes
              ? row.orderNotes
              : '暂无备注'
            this.lookAddressModel.shippingCompany = row.shippingCompany
            this.lookAddressModel.courierNumber = row.courierNumber
            this.lookAddressModel.deliveryTime = row.deliveryTime
            if (row.orderType == 1 && (row.orderState == 4 || row.orderState == 5)) {
              this.lookAddressModel.isHaveLogistics = true
            } else {
              this.lookAddressModel.isHaveLogistics = false
            }
            this.dialogLookAddressVisible = true
          } else {
            this.$message.error('您没有权限查看')
          }
          break
        case 3:
          if (this.permission.lookLogisticsInfo) {
            this.lookAddressModel.shippingCompany = row.shippingCompany
            this.lookAddressModel.courierNumber = row.courierNumber
            this.dialogLooklogisticsVisible = true
          } else {
            this.$message.error('您没有权限查看')
          }
          break
        default:
          break
      }
    },
    changeLogisticsHandle(row) {
      this.lookAddressModel.courierNumber = row;
      this.refresCurrentPagehData()
    },
    deliverGoodsHandle(row) {
      this.logisticsModel.orderId = row.orderId
      this.dialogShowSendOrderVisible = true
    },

    closeSendOrder() {
      this.logisticsModel.shippingCompany = ''
      this.logisticsModel.courierNumber = ''
      this.logisticsModel.orderId = 0
      this.$refs['logisticsRef'].clearValidate()
    },
    //发货
    async sendOrderSubmit() {
      this.$refs.logisticsRef.validate(async (valid) => {
        if (valid) {
          this.sendOrderLoading = true
          try {
            let data = await goodsManager.deliverGoods(this.logisticsModel)
            if (data.code == 200) {
              this.$message({ message: '发货成功', type: 'success' })
              this.dialogShowSendOrderVisible = false
              this.refresCurrentPagehData()
            }
          } catch (error) {
            //
          } finally {
            this.sendOrderLoading = false
          }
        } else {
          return false
        }
      })
    },
    async confirmReceiptHandle(row) {
      try {
        let data = await goodsManager.confirmReceipt({ orderId: row.orderId })
        if (data.code == 200) {
          this.$message({ message: '自提成功', type: 'success' })
          this.refresCurrentPagehData()
        }
      } catch (error) {
        //
      } finally {
        this.sendOrderLoading = false
      }
    },
    exportExcelHandle() {
      // var paras = {
      //   orderSn: this.query.orderSn,
      //   orderState: this.query.orderState,
      //   orderSource: this.query.orderSource,
      //   nickName: this.query.nickName,
      //   storeName: this.query.storeName,
      //   startTime:this.query.startTime,
      //   endTime:this.query.endTime,
      // }
      let url = baseconfig.baseUrl + '/web/integralOrderInfo/exportExcel' + '?'
      for (const propName of Object.keys(this.query)) {
        const value = this.query[propName]
        var part = encodeURIComponent(propName) + '='
        if (value !== null && typeof value !== 'undefined') {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              if (value[key] !== null && typeof value[key] !== 'undefined') {
                let params = propName + '[' + key + ']'
                let subPart = encodeURIComponent(params) + '='
                url += subPart + encodeURIComponent(value[key]) + '&'
              }
            }
          } else {
            url += part + encodeURIComponent(value) + '&'
          }
        }
      }
      window.open(url)
    },
    newExportExcelHandle() {
      let url = baseconfig.baseUrl + '/web/integralOrderInfo/newExportExcel' + '?'
      for (const propName of Object.keys(this.query)) {
        const value = this.query[propName]
        var part = encodeURIComponent(propName) + '='
        if (value !== null && typeof value !== 'undefined') {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              if (value[key] !== null && typeof value[key] !== 'undefined') {
                let params = propName + '[' + key + ']'
                let subPart = encodeURIComponent(params) + '='
                url += subPart + encodeURIComponent(value[key]) + '&'
              }
            }
          } else {
            url += part + encodeURIComponent(value) + '&'
          }
        }
      }
      window.open(url)
    },
    downloadDeliveryTemplateHandle() {
      // 创建一个<a></a>标签
      const a = document.createElement('a')
      // 给a标签的href属性值加上地址,注意:这里是绝对路径,不用加 点.
      a.href = '/批量发货模版.xlsx'
      // 设置下载文件文件名,这里加上.xlsx指定文件类型,pdf文件就指定.fpd即可
      a.download = '批量发货模版.xlsx'
      // 障眼法藏起来a标签
      a.style.display = 'none'
      // 将a标签追加到文档对象中
      document.body.appendChild(a)
      // 模拟点击了<a>标签,会触发<a>标签的href的读取,浏览器就会自动下载了
      a.click()
      // 一次性的,用完就删除a标签
      a.remove()
    },
    handleAvatarSuccess(res, file, fileList) {
      console.log('file222', res, fileList)
      if (res.code == 200) {
        this.$message({ message: res.message, type: 'success' })
        this.refreshData()
      } else {
        this.$message.error(res.message)
      }
      this.productImgList = []
    },
    beforeAvatarUpload(file) {
      console.log('file11', file)

      //文件上传之前调用做一些拦截限制
      let beforeUploadObj = {
        uid: file.uid,
        url: '',
      }
    },
    handleExceed(files, productImgList) {
      //图片上传超过数量限制
      console.log('iiii', files.length)
      this.$message.error('上传文件不能超过' + this.imgLimit + '个!')
    },
    imgUploadError(err, file, productImgList) {
      //图片上传失败调用
      this.$message.error('导入失败!')
    },
  },
}
</script>

<style lang="scss" scoped></style>
