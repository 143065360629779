<template>
  <div>
    <h4>物流公司：{{shippingCompany}}    物流单号：{{courierNumber}}</h4>
    <el-timeline style="margin-top:40px" :reverse="reverse" v-if="logisticsList.length" v-loading="showLoading">
      <el-timeline-item v-for="(activity, index) in logisticsList" :key="index" :timestamp="activity.AcceptTime">
        {{activity.AcceptStation}}
      </el-timeline-item>
    </el-timeline>
    <el-empty style="margin-top:40px" v-else description="暂无物流信息" v-loading="showLoading"></el-empty>
  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import goodsManager from '@/request/goodsManager.js'
export default {
  mixins: [getList],
  props: ['courierNumber','shippingCompany'],
  data() {
    return {
      reverse: false,
      logisticsList: [],
    }
  },
  created() {
    this.getLogisticsInfo()
  },
  methods: {
    async getLogisticsInfo() {
      try {
        this.showLoading = true;
        let data = await goodsManager.getLogisticsInfo({
          courierNumber: this.courierNumber,
        })
        if (data.code == 200) {
          this.logisticsList = Array.prototype.reverse.call(data.data)
        }
      } catch (error) {
        //
      }finally{
        this.showLoading = false;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>