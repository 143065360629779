var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "fr-s-s warp", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      type: "text",
                      placeholder: "搜索订单编号、用户名、门店",
                    },
                    model: {
                      value: _vm.query.keyword,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "keyword", $$v)
                      },
                      expression: "query.keyword",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入商品名称" },
                    model: {
                      value: _vm.query.goodsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "goodsName", $$v)
                      },
                      expression: "query.goodsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "190px" },
                    attrs: {
                      size: "small",
                      "value-format": "timestamp",
                      type: "datetime",
                      placeholder: "请选择开始时间",
                      "picker-options": _vm.pickerOptionsStart,
                    },
                    model: {
                      value: _vm.query.startTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "startTime", $$v)
                      },
                      expression: "query.startTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "190px" },
                    attrs: {
                      size: "small",
                      "value-format": "timestamp",
                      type: "datetime",
                      placeholder: "请选择结束时间",
                      "picker-options": _vm.pickerOptionsEnd,
                    },
                    model: {
                      value: _vm.query.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "endTime", $$v)
                      },
                      expression: "query.endTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "small",
                        placeholder: "请选择订单状态",
                        clearable: "",
                      },
                      model: {
                        value: _vm.query.orderState,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "orderState", $$v)
                        },
                        expression: "query.orderState",
                      },
                    },
                    _vm._l(_vm.searchForm.orderStateList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "small",
                        placeholder: "请选择订单来源",
                        clearable: "",
                      },
                      model: {
                        value: _vm.query.orderSource,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "orderSource", $$v)
                        },
                        expression: "query.orderSource",
                      },
                    },
                    _vm._l(_vm.searchForm.orderSourceList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "small",
                        placeholder: "请选择订单类型",
                        clearable: "",
                      },
                      model: {
                        value: _vm.query.orderType,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "orderType", $$v)
                        },
                        expression: "query.orderType",
                      },
                    },
                    _vm._l(_vm.searchForm.orderTypeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.refreshData },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
              _vm.permission.newExportExcel
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.newExportExcelHandle },
                        },
                        [_vm._v("订单导出")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.permission.exportExcel
                ? _c(
                    "el-form-item",
                    [
                      _vm.permission.exportExcel
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", plain: "" },
                              on: { click: _vm.downloadDeliveryTemplateHandle },
                            },
                            [_vm._v("下载发货模版")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.permission.excelImport
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "elUploadRef",
                          staticStyle: { width: "0px", height: "0px" },
                          attrs: {
                            action: _vm.uploadUrl,
                            accept: ".xlsx",
                            limit: _vm.imgLimit,
                            "file-list": _vm.productImgList,
                            multiple: _vm.isMultiple,
                            "show-file-list": false,
                            "on-success": _vm.handleAvatarSuccess,
                            "before-upload": _vm.beforeAvatarUpload,
                            "on-exceed": _vm.handleExceed,
                            "on-error": _vm.imgUploadError,
                          },
                        },
                        [
                          _vm.permission.excelImport
                            ? _c(
                                "el-button",
                                { attrs: { type: "primary", plain: "" } },
                                [_vm._v("批量发货")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                "row-key": "orderId",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand", width: "40" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        props.row.deliveryTime != 0
                          ? _c(
                              "el-descriptions",
                              {
                                attrs: {
                                  title: "物流信息",
                                  border: "",
                                  column: 3,
                                },
                              },
                              [
                                _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "物流公司" } },
                                  [_vm._v(_vm._s(props.row.shippingCompany))]
                                ),
                                _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "物流单号" } },
                                  [_vm._v(_vm._s(props.row.courierNumber))]
                                ),
                                _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "发货时间" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          props.row.deliveryTime
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-descriptions",
                          { attrs: { title: "时间", border: "", column: 3 } },
                          [
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "确认收货时间" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDate")(
                                      props.row.confirmReceiptTime
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "自提时间" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDate")(props.row.pickUpTime)
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "支付时间" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDate")(props.row.paymentTime)
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "orderId",
                  label: "编号",
                  align: "center",
                  width: "50",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "订单编号",
                  prop: "orderSn",
                  "min-width": "140",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "用户名", prop: "nickName", "min-width": "90" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "门店名称",
                  prop: "storeName",
                  "min-width": "120",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "订单状态", width: "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("getStateName")(
                                scope.row.orderState,
                                _vm.searchForm.orderStateList
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "订单类型", align: "center", width: "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.orderType == 1
                          ? _c("span", [_vm._v("物流")])
                          : scope.row.orderType == 2
                          ? _c("span", [_vm._v("自提")])
                          : _c("span", [_vm._v("--")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "订单来源", align: "center", width: "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("getStateName")(
                                scope.row.orderSource,
                                _vm.searchForm.orderSourceList
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "运费",
                  align: "center",
                  width: "90",
                  prop: "logisticsFees",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "支付积分",
                  align: "center",
                  width: "90",
                  prop: "orderPayIntegral",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "订单商品总额",
                  align: "center",
                  width: "100",
                  prop: "orderPayAmount",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "下单时间", align: "center", width: "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("formatDate")(scope.row.orderTime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "100px",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "详情",
                              placement: "top",
                            },
                          },
                          [
                            _c("el-button", {
                              staticClass: "success",
                              attrs: { type: "text", icon: "el-icon-tickets" },
                              on: {
                                click: function ($event) {
                                  return _vm.showContentHandle(scope.row, 5)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.permission.deliverGoods && scope.row.orderState == 2
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "发货",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-s-promotion",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deliverGoodsHandle(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        scope.row.orderType == 1 &&
                        (scope.row.orderState == 4 || scope.row.orderState == 5)
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "查看物流",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-truck",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showContentHandle(scope.row, 3)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.confirmReceipt &&
                        scope.row.orderState == 3
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "确认自提",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "确认用户已自提？",
                                      "icon-color": "red",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.confirmReceiptHandle(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "danger",
                                      attrs: {
                                        slot: "reference",
                                        icon: "el-icon-shopping-bag-1",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            ref: "pagination",
            attrs: {
              "current-page": _vm.query.page,
              "page-sizes": [10, 30, 50, 100],
              "page-size": _vm.query.page_size,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单发货",
            visible: _vm.dialogShowSendOrderVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogShowSendOrderVisible = $event
            },
            close: _vm.closeSendOrder,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "logisticsRef",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.logisticsModel,
                rules: _vm.logisticsOrderRules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "物流公司", prop: "shippingCompany" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入物流公司" },
                    model: {
                      value: _vm.logisticsModel.shippingCompany,
                      callback: function ($$v) {
                        _vm.$set(_vm.logisticsModel, "shippingCompany", $$v)
                      },
                      expression: "logisticsModel.shippingCompany",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "物流单号", prop: "courierNumber" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入物流单号" },
                    model: {
                      value: _vm.logisticsModel.courierNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.logisticsModel, "courierNumber", $$v)
                      },
                      expression: "logisticsModel.courierNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "medium" },
                  on: {
                    click: function ($event) {
                      _vm.dialogShowSendOrderVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "medium",
                    icon: "el-icon-document-checked",
                    type: "primary",
                    loading: _vm.sendOrderLoading,
                  },
                  on: { click: _vm.sendOrderSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("show-content", {
        attrs: { title: _vm.rowInfo.title, content: _vm.rowInfo.content },
        model: {
          value: _vm.dialogSeeContent,
          callback: function ($$v) {
            _vm.dialogSeeContent = $$v
          },
          expression: "dialogSeeContent",
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看物流",
            visible: _vm.dialogLooklogisticsVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLooklogisticsVisible = $event
            },
          },
        },
        [
          _vm.dialogLooklogisticsVisible
            ? _c("LookLogistics", {
                attrs: {
                  courierNumber: _vm.lookAddressModel.courierNumber,
                  shippingCompany: _vm.lookAddressModel.shippingCompany,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看详情",
            visible: _vm.dialogLookOrderDetailVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLookOrderDetailVisible = $event
            },
          },
        },
        [
          _vm.dialogLookOrderDetailVisible
            ? _c("OrderDetail", {
                attrs: { dataInfo: _vm.orderInfo },
                on: { changeLogistics: _vm.changeLogisticsHandle },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }