<template>
    <div class="container">
        <div class="page-container">
            <el-card class="m-t-10 m-b-20">
                <div slot="header" class="clearfix">
                    <div class="fr-b-c">
                        基本信息
                    </div>
                </div>
                <div class=" fr-s-s">
                    <p class="w-5">订单编号：{{ dataInfo.orderSn }}</p>
                    <p class="w-5">下单用户：{{ dataInfo.nickName }}</p>
                    <p class="w-5">店铺名称：{{ dataInfo.storeName }}</p>
                </div>
            </el-card>
            <el-card class="box-card m-b-20" v-if="dataInfo.orderType == 1">
                <div slot="header" class="clearfix">
                    <span>收货地址</span>
                </div>
                <div v-for="o in 3" :key="o" class="box-card-content">
                    <template v-if="o == 1">
                        联系人:{{ addreesInfo.consignee || ' ' }}
                    </template>
                    <template v-else-if="o == 2">
                        联系电话:{{ addreesInfo.telphone || ' ' }}
                    </template>
                    <template v-else-if="o == 3">
                        详细地址:{{ addreesInfo.content || ' ' }}
                    </template>
                </div>
            </el-card>
            <el-card class="box-card m-b-20"
                v-if="dataInfo.orderType == 1 && (dataInfo.orderState == 4 || dataInfo.orderState == 5)">
                <div slot="header" class="clearfix">
                    <span>包裹信息</span>
                </div>
                <div v-for="o in 3" :key="o" class="box-card-content">
                    <template v-if="o == 1">
                        快递公司: {{ editForm.shippingCompany }}
                    </template>
                    <template v-else-if="o == 2">
                        快递单号: {{ editForm.courierNumber }} <el-button type="text" class="m-l-20"
                            @click="editLogisticsHandle" v-if="permission.updateIntegralOrderInfo">修改单号</el-button>
                    </template>
                    <template v-if="o == 3">
                        发货时间: {{ editForm.deliveryTime | formatDate }}
                    </template>
                </div>
            </el-card>
            <el-card class="m-b-20">
                <div slot="header" class="clearfix">
                    <div class="fr-b-s">
                        <span>订单商品</span>
                    </div>
                </div>
                <div>
                    <div v-for="item, index in dataInfo.orderGoodsDetailList" :key="index" class="goods-info">
                        <el-image class="goods-image"  :src="item.goodsImage" fit="fill"
                            :preview-src-list="[item.goodsImage]"></el-image>
                        <div class="goods-content fl-b-s">
                            <div class="goods-name">
                                <span>{{ item.goodsName }}</span>
                            </div>
                            <div class="goods-price-number w-1 fr-b-c">
                                <span class="">¥{{ item.goodsPrice }}</span>
                                <span>x{{ item.quantity }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <el-divider></el-divider>
                <div class="goods-data-text">商品数量:{{ totalNumber(dataInfo.orderGoodsDetailList) }} 订单金额：¥{{
                    dataInfo.orderPayAmount }}</div>
            </el-card>
            <el-card class="box-card m-b-20" v-if="dataInfo.orderNotes && dataInfo.orderNotes.length > 0">
                <div slot="header" class="clearfix">
                    <span>备注</span>
                </div>
                <div class="box-card-content">
                    {{ '备注: ' + dataInfo.orderNotes }}
                </div>
            </el-card>
        </div>

        <!-- 修改物流信息-->
        <el-dialog :title="addOrEditTitle" :visible.sync="addOrEditDialog" :modal="false" :destroy-on-close="true"
            fullscreen @close="resetAddOrEditData()">
            <el-form label-width="150px" ref="editForm" :model="editForm" :rules="addRules">
                <el-form-item label="快递单号" prop="courierNumber">
                    <el-input size="medium" v-model="editForm.courierNumber" placeholder="请输入快递单号"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="resetAddOrEditData()">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import { getList } from '@/tools/getList.js'
import goodsManager from '@/request/goodsManager.js'
export default {
    props: ['dataInfo'],
    mixins: [getList],
    // import引入的组件需要注入到对象中才能使用
    components: {

    },
    data() {
        // 这里存放数据
        return {
            addreesInfo: {
                consignee: '',
                telphone: '',
                content: ''
            },
            //修改单号
            addOrEditDialog: false,
            addOrEditTitle: '',
            addOrEditBtnLoading: false,
            editForm: {
                orderId: this.dataInfo.orderId,
                courierNumber: this.dataInfo.courierNumber,
                shippingCompany: this.dataInfo.shippingCompany,
                deliveryTime: this.dataInfo.deliveryTime
            },
            addRules: {
                courierNumber: [
                    { required: true, message: '请输入快递单号', trigger: 'blur' },
                ],
            },
        }
    },
    // 监听属性 类似于data概念
    computed: {
        totalNumber() {
            return (val) => {
                var number = 0;
                val.forEach(obj => {
                    number += obj.quantity
                });
                return number
            }
        }
    },
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.handlePermission()
        if (this.dataInfo.orderType == 1) {
            // 快递
            if (this.permission.lookOrderAddressInfo) {
                this.getAddressInfo()
            }
        }


    },
    filter: {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        async getAddressInfo() {
            try {
                this.showLoading = true
                let data = await goodsManager.getAddressInfo({ orderId: this.dataInfo.orderId })
                if (data.code == 200) {
                    this.addreesInfo.consignee = data.data.consignee
                    this.addreesInfo.telphone = data.data.telphone
                    this.addreesInfo.content =
                        data.data.provinceName +
                        data.data.cityName +
                        data.data.districtName +
                        data.data.addressDetail
                }
            } catch (error) {
                //
            } finally {
                this.showLoading = false
            }
        },

        editLogisticsHandle() {
            this.addOrEditTitle = '修改单号'
            this.addOrEditDialog = true
        },
        clickAddOrEditCancel() {
            this.resetAddOrEditData()
        },
        clickAddOrEditConfirm() {
            this.$refs.editForm.validate(async (valid) => {
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true
                        try {
                            let { code, message } = await goodsManager.updateIntegralOrderInfo(
                                {
                                    orderId: this.editForm.orderId,
                                    courierNumber: this.editForm.courierNumber,
                                }
                            )
                            if (code === 200) {
                                this.$message({ message: message, type: 'success' })
                                this.$emit('changeLogistics', this.editForm.courierNumber)
                                this.resetAddOrEditData();
                            }
                        } catch (error) {
                            console.log(error)
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }
                }
            })
        },
        resetAddOrEditData() {
            this.editForm.courierNumber = this.dataInfo.courierNumber;
            this.addOrEditDialog = false
        },
    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped>
.goods-info {
    display: flex;
    margin: 10px 0px;
    height: 90px;
}

.goods-image{
    width: 90px;
    height: 90px;
    flex-shrink: 0;
}

.goods-content {
    flex-grow: 1;
    margin-left: 10px;
}

.goods-name {
    font-size: 16px;
    font-weight: 400px;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.goods-price-number {
    font-size: 14px;
    color: gray;
}

.goods-data-text {
    font-size: 16px;
    word-spacing: 12px;
}
</style>
