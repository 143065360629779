<template>
  <div class="container">
    <div class="page-container">
      <el-card class="box-card" v-loading="showLoading" style="margin-bottom:20px">
        <div slot="header" class="clearfix">
          <span>收货地址</span>
        </div>
        <div v-for="o in 3" :key="o" class="box-card-content">
          <template v-if="o==1">
            {{'联系人: ' + addreesInfo.consignee }}
          </template>
          <template v-else-if="o==2">
            {{'联系电话: ' + addreesInfo.telphone }}
          </template>
          <template v-else-if="o==3">
            {{'详细地址: ' + addreesInfo.content }}
          </template>
        </div>
      </el-card>
      <el-card class="box-card" style="margin-bottom:20px" v-if="isHaveLogistics">
        <div slot="header" class="clearfix">
          <span>包裹信息</span>
        </div>
        <div v-for="o in 3" :key="o" class="box-card-content">
          <template v-if="o==1">
            {{'快递公司: ' + editForm.shippingCompany }}
          </template>
          <template v-else-if="o==2">
            {{'快递单号: ' + editForm.courierNumber }} <el-button type="text" style="margin-left:20px" @click="editLogisticsHandle">修改单号</el-button>
          </template>
           <template v-if="o==3">
            发货时间:   {{editForm.deliveryTime | formatDate }}
          </template>
        </div>
      </el-card>

      <el-card class="box-card" style="margin-bottom:20px" v-if="orderNotes.length>0">
        <div slot="header" class="clearfix">
          <span>备注</span>
        </div>
        <div class="box-card-content">
          {{'备注: ' + orderNotes }}
        </div>
      </el-card>
    </div>

     <!-- 添加商品分类-->
    <el-dialog
      :title="addOrEditTitle"
      :visible.sync="addOrEditDialog"
      :modal="false"
      :destroy-on-close="true"
      fullscreen
      @close="resetAddOrEditData()"
    >
      <el-form label-width="150px" ref="editForm" :model="editForm" :rules="addRules">
        <el-form-item label="快递单号" prop="courierNumber">
          <el-input
            size="medium"
            v-model="editForm.courierNumber"
            placeholder="请输入快递单号"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetAddOrEditData()">取 消</el-button>
        <el-button
          type="primary"
          :loading="addOrEditBtnLoading"
          @click="clickAddOrEditConfirm"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import goodsManager from '@/request/goodsManager.js'
export default {
  mixins: [getList],
  props: [
    'orderId',
    'orderNotes',
    'courierNumber',
    'shippingCompany',
    'deliveryTime',
    'isHaveLogistics',
  ],
  data() {
    return {
      addreesInfo: {
        consignee: '',
        telphone: '',
        content: '',
      },
      //修改单号
      addOrEditDialog: false,
      addOrEditTitle: '',
      addOrEditBtnLoading: false,
      editForm: {
        orderId: this.orderId,
        courierNumber: this.courierNumber,
        shippingCompany: this.shippingCompany,
        deliveryTime:this.deliveryTime
      },
      addRules: {
        courierNumber: [
          { required: true, message: '请输入快递单号', trigger: 'blur' },
        ],
      },
    }
  },

  created() {
    this.getAddressInfo()
  },

  methods: {
    async getAddressInfo() {
      try {
        this.showLoading = true
        let data = await goodsManager.getAddressInfo({ orderId: this.orderId })
        if (data.code == 200) {
          this.addreesInfo.consignee = data.data.consignee
          this.addreesInfo.telphone = data.data.telphone
          this.addreesInfo.content =
            data.data.provinceName +
            data.data.cityName +
            data.data.districtName +
            data.data.addressDetail
        }
      } catch (error) {
        //
      } finally {
        this.showLoading = false
      }
    },
    editLogisticsHandle() {
      this.addOrEditTitle = '修改单号'
      this.addOrEditDialog = true
    },

    clickAddOrEditCancel() {
      this.resetAddOrEditData()
    },
    clickAddOrEditConfirm() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          try {
            this.addOrEditBtnLoading = true
            try {
              let { code, message } = await goodsManager.updateIntegralOrderInfo(
                {
                  orderId: this.editForm.orderId,
                  courierNumber: this.editForm.courierNumber,
                }
              )
              if (code === 200) {
                this.$message({ message: message, type: 'success' })
                this.$emit('changeLogistics',this.editForm.courierNumber)
                this.resetAddOrEditData();
              }
            } catch (error) {
              console.log(error)
            }
          } catch (error) {
            console.log(error)
          } finally {
            this.addOrEditBtnLoading = false
          }
        }
      })
    },
    resetAddOrEditData() {
      this.editForm.courierNumber = this.courierNumber;
      this.addOrEditDialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
.box-card-content {
  margin-bottom: 5px;
  margin-top: 5px;
}
</style>
