var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-card",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              staticClass: "box-card",
              staticStyle: { "margin-bottom": "20px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [_c("span", [_vm._v("收货地址")])]
              ),
              _vm._l(3, function (o) {
                return _c(
                  "div",
                  { key: o, staticClass: "box-card-content" },
                  [
                    o == 1
                      ? [
                          _vm._v(
                            " " +
                              _vm._s("联系人: " + _vm.addreesInfo.consignee) +
                              " "
                          ),
                        ]
                      : o == 2
                      ? [
                          _vm._v(
                            " " +
                              _vm._s("联系电话: " + _vm.addreesInfo.telphone) +
                              " "
                          ),
                        ]
                      : o == 3
                      ? [
                          _vm._v(
                            " " +
                              _vm._s("详细地址: " + _vm.addreesInfo.content) +
                              " "
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
            ],
            2
          ),
          _vm.isHaveLogistics
            ? _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "20px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_c("span", [_vm._v("包裹信息")])]
                  ),
                  _vm._l(3, function (o) {
                    return _c(
                      "div",
                      { key: o, staticClass: "box-card-content" },
                      [
                        o == 1
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    "快递公司: " + _vm.editForm.shippingCompany
                                  ) +
                                  " "
                              ),
                            ]
                          : o == 2
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    "快递单号: " + _vm.editForm.courierNumber
                                  ) +
                                  " "
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: { type: "text" },
                                  on: { click: _vm.editLogisticsHandle },
                                },
                                [_vm._v("修改单号")]
                              ),
                            ]
                          : _vm._e(),
                        o == 3
                          ? [
                              _vm._v(
                                " 发货时间: " +
                                  _vm._s(
                                    _vm._f("formatDate")(
                                      _vm.editForm.deliveryTime
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.orderNotes.length > 0
            ? _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-bottom": "20px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_c("span", [_vm._v("备注")])]
                  ),
                  _c("div", { staticClass: "box-card-content" }, [
                    _vm._v(" " + _vm._s("备注: " + _vm.orderNotes) + " "),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.addOrEditTitle,
            visible: _vm.addOrEditDialog,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addOrEditDialog = $event
            },
            close: function ($event) {
              return _vm.resetAddOrEditData()
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                "label-width": "150px",
                model: _vm.editForm,
                rules: _vm.addRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "快递单号", prop: "courierNumber" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入快递单号" },
                    model: {
                      value: _vm.editForm.courierNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "courierNumber", $$v)
                      },
                      expression: "editForm.courierNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetAddOrEditData()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.addOrEditBtnLoading },
                  on: { click: _vm.clickAddOrEditConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }