var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h4", [
        _vm._v(
          "物流公司：" +
            _vm._s(_vm.shippingCompany) +
            " 物流单号：" +
            _vm._s(_vm.courierNumber)
        ),
      ]),
      _vm.logisticsList.length
        ? _c(
            "el-timeline",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              staticStyle: { "margin-top": "40px" },
              attrs: { reverse: _vm.reverse },
            },
            _vm._l(_vm.logisticsList, function (activity, index) {
              return _c(
                "el-timeline-item",
                { key: index, attrs: { timestamp: activity.AcceptTime } },
                [_vm._v(" " + _vm._s(activity.AcceptStation) + " ")]
              )
            }),
            1
          )
        : _c("el-empty", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.showLoading,
                expression: "showLoading",
              },
            ],
            staticStyle: { "margin-top": "40px" },
            attrs: { description: "暂无物流信息" },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }