import { render, staticRenderFns } from "./GoodsClassList.vue?vue&type=template&id=13acde99&scoped=true"
import script from "./GoodsClassList.vue?vue&type=script&lang=js"
export * from "./GoodsClassList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13acde99",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/ap/jenkins/workspace/prod-ykd360-admin-front(医可达旧管理端)/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13acde99')) {
      api.createRecord('13acde99', component.options)
    } else {
      api.reload('13acde99', component.options)
    }
    module.hot.accept("./GoodsClassList.vue?vue&type=template&id=13acde99&scoped=true", function () {
      api.rerender('13acde99', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/page/goods/GoodsClassList.vue"
export default component.exports